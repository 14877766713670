// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Div, H1, Span } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    ${props.theme.vh}
    position: fixed;
    z-index: 10;
    top: 0; left: 0; right: 0;
    background: ${props.theme.colors.brand.bc1};
    padding-top: ${props.specialPadding - 3.6}rem;


    .row,
    .col {
        height: 100%;
    }
`);

export const FakeJacket = styled(Section)(props => css`
    width: 100%;
    ${props.theme.vh}
`);

export const Content = styled(Div)(props => css`
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2.4rem;

    width: 100%;
    height: 100%;

    padding-bottom: 9.6rem;

    ${breakup.large`
        padding-bottom: 8.4rem;
        justify-content: space-between;
    `}
`);


export const Title = styled(H1)(props => css`
    display: flex;
    align-items: center;
    text-align: center; 
    justify-content: center;

    ${breakup.large`
        flex: 1;
        text-align: left;
        justify-content: flex-start;
    `}
`);


export const Excerpt = styled(Span)(props => css`
    opacity: 0.6;
    text-align: center;

    ${breakup.large` text-align: left; `}
`);


export const Video = styled(Div)(props => css`
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0; 
    z-index: 1;
    overflow: hidden;

    > div {
        
        video {
            object-fit: cover;
        }
    }
`);


export const ScrollButtonPos = styled.div(props => css`
    position: absolute;
    bottom: 3.6rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 12;

    ${breakup.large`
        bottom: 8.4rem;
        left: auto;
        transform: none;
        right: calc(8.333vw + 1.8rem);
    `}
`);
