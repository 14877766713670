// Imports
// ------
import styled, { css } from 'styled-components';

// Exports
// ------
export const Jacket = styled.div((props) => `
	width: 100vw;
	height: 100vh;
	overflow-x: none;
	overflow: auto;

	${props.noscroll && css`
		pointer-events: none;

		.scroll-content {
			pointer-events: none;
		}
	`}
`);
