import { ScrollbarPlugin } from "smooth-scrollbar"

export default class StopScrollPlugin extends ScrollbarPlugin {
    static pluginName = "stopScroll"

    static defaultOptions = {
        scroll: true
    }

    transformDelta(delta) {
        return !this.options.scroll ? { x: 0, y: 0 } : delta
    }
}
