// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, ContentBlock } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    z-index: 15;
    min-height: 100vh;
    background: ${props.theme.colors.brand.bc1};
`);

export const Content = styled(ContentBlock)(props => css`
   
`);
