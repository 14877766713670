// Imports
// ------------
import React, { useState, useRef, useEffect } from 'react';
import { Row, Column } from '@waffl';
import { observer } from 'mobx-react-lite';
import { menuState } from '@states';
import ReactPlayer from 'react-player/file';
import ScrollButton from '@parts/ScrollButton';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Video
import vid from '@images/std-hero.mp4';

// Styles
// ------------
import { Jacket, Content, ScrollButtonPos, Title, Excerpt, Video, FakeJacket } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const LegalHero = ({ title, effectiveDate }) => {
    // NOTE • Refs
    const videoRef = useRef();

    // NOTE • State
    const [ready, setReady] = useState(false);

    // NOTE • Refs
    const trigger = useRef();
    const target = useRef();

    // NOTE • Functions
    const handleReady = () => {
        setReady(true);
    };


    // NOTE • Video Functions (Performance based)
    const play = () => {
        setReady(true);
    }

    const pause = () => {
        setReady(false);
    }

    // NOTE • Onload - Animation
    useEffect(() => {
        const timer = setTimeout(() => {
            gsap.to(target.current, {
                y: "-30%",
                ease: 'none',
                scrollTrigger: {
                    trigger: trigger.current,
                    start: '100% 100%',
                    end: '200% 100%',
                    scrub: 0.5,
                    markers: false,
                },
            });

            gsap.to(target.current, {
                opacity: 0,
                ease: 'none',
                immediateRender: false,
                scrollTrigger: {
                    trigger: trigger.current,
                    start: '101% 0%',
                    end: '102% 0%',
                    scrub: 0,
                    markers: false,
                },
            });

            gsap.to(videoRef.current, {
                ease: 'none',
                scrollTrigger: {
                    trigger: videoRef.current,
                    start: "100% 0%",
                    end: "100% 0%",
                    scrub: 0,
                    onLeave: pause,
                    onEnterBack: play,
                    markers: false,
                }
            });
        }, 250);

        return () => {
            clearTimeout(timer);
        }
    }, []);


    return (
        <>
            <Jacket specialPadding={menuState.heightSize} ref={target}>
                <Row isExpanded isMaxHeight>
                    <Column small={12} large={5} pushLarge={1} mpad isMaxHeight>
                        <Content>
                            <Title>{title}</Title>
                            <Excerpt>Last updated: {effectiveDate}</Excerpt>
                        </Content>
                    </Column>
                </Row>

                <ScrollButtonPos>
                    <ScrollButton isDown hash="intro" />
                </ScrollButtonPos>

                <Video ref={videoRef}>
                    <ReactPlayer
                        url={vid}
                        onReady={handleReady}
                        playing={ready}
                        loop
                        muted
                        playsinline
                        width={`100%`}
                        height={`100%`}
                        controls={false}
                    />
                </Video>
            </Jacket>

            <FakeJacket ref={trigger} />
        </>
    );
}

export default observer(LegalHero);