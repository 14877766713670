// Imports
// ------
import React from 'react';
import SmoothScroll from '@parts/SmoothScroll';
import LegalHero from '@parts/Legal/LegalHero';
import LegalContent from '@parts/Legal/LegalContent';
import Footer from '@parts/Footer';
import Seo from '@parts/Seo';
import { graphql } from 'gatsby';


// Component
// ------
const LegalPost = ({ data, pageContext }) => {
	return (
		<>
			<Seo
				title={data.post.seoMeta?.title}
				desc={data.post.seoMeta?.desc}
			/>

			<LegalHero
				title={data.post.heroTitle}
				effectiveDate={data.post.effectiveDate}
			/>

			<LegalContent content={data.post.content.value} />

			<Footer />
		</>
	);
}

export default LegalPost;


// GraphQL
// ------------
export const query = graphql`
	query LegalQuery ($slug: String!) {
		post: datoCmsLegalPage(slug: { eq: $slug }) {
			# ANCHOR • Seo
			seo: seoMeta {
				title
				desc: description
			}

			heroTitle: pageTitle
			effectiveDate(formatString: "MMMM DD, YYYY")
			content {
				value
			}
		}
	}
`
