// Imports
// ------------
import React from 'react';
import { StructuredText } from 'react-datocms';
import { Row, Column } from '@waffl';

// Styles
// ------------
import { Jacket, Content } from './styles';

// Component
// ------------
const LegalContent = ({ content }) => {
    return (
        <Jacket pad id="intro">
            <Row isExpanded>
                <Column small={12} medium={10} pushMedium={1} large={8} pushLarge={2} mpad>
                    <Content>
                        <StructuredText data={content} />
                    </Content>
                </Column>
            </Row>
        </Jacket>
    );
}

export default LegalContent;